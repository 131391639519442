export type AccordionToggleState = 'open' | 'close' | 'openNext';
interface AccordionToggleEventDetail {
  to: AccordionToggleState;
}
export type AccordionToggleEvent = CustomEvent<AccordionToggleEventDetail>;

export const AccordionToggleEventName = 'accordion-toggle';

declare global {
  interface HTMLElementEventMap {
    [AccordionToggleEventName]: AccordionToggleEvent;
  }
}
