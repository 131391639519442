import {LitElement, html, css, type PropertyValues} from 'lit';
import {customElement, property} from 'lit/decorators.js'; // eslint-disable-line import/extensions

import {type BaseAccordionItemElement} from './accordion-items/base-accordion-item';
import {type AccordionToggleEvent, AccordionToggleEventName} from './utils/accordionToggleStateEvent';

@customElement('accordion-content')
export class AccordionContentElement extends LitElement {
  @property({type: Boolean})
  public accessor collapsible = false;

  @property({type: String, attribute: 'child-type'})
  public accessor childType = 'accordion-item';

  private get _items(): Array<BaseAccordionItemElement> {
    const slot = this.shadowRoot?.querySelector('slot');
    return slot?.assignedElements()
      .filter((node) => node.matches(this.childType)) as Array<BaseAccordionItemElement> ?? [];
  }

  static styles = css`
    :host {
      display: block;
    }
  `;

  render() {
    return html`
      <div class="accordion-wrapper">
        <slot @slotchange="${this._handleSlotchange}"></slot>
      </div>
    `;
  }

  firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);

    // After first updating is done, check for any items matching URL hash and open it
    this.updateComplete.then(() => {
      const urlHash = decodeURI(String(window.location.hash)).toLowerCase();
      this._items.find((el) => urlHash.includes(el.id.toLowerCase()))?.open();
    });
  }

  disconnectedCallback() {
    this._items.forEach((el) => el.removeEventListener(AccordionToggleEventName, this._toggleCallback));
    super.disconnectedCallback();
  }

  private _handleSlotchange() {
    this._setItemListeners();
  }

  private _setItemListeners() {
    const toggleListener = 'active';
    const untrackedItems = this._items.filter((el) => el.dataset.toggleListener !== toggleListener);
    for (const item of untrackedItems) {
      item.addEventListener(AccordionToggleEventName, this._toggleCallback);
      item.dataset.toggleListener = toggleListener;
    }
  }

  private _toggleCallback = (e: AccordionToggleEvent) => {
    const target = e.target as BaseAccordionItemElement;
    switch (e.detail.to) {
      case 'open':
        this._openItem(target);
        break;
      case 'openNext':
        this._closeItem(target);
        if (target.nextElementSibling !== null) {
          this._openItem(target.nextElementSibling as BaseAccordionItemElement);
        }
        break;
      case 'close':
        this._closeItem(target);
        break;
      default:
        throw new Error(`Invalid value '${e.detail.to}' for AccordionToggleEvent`);
    }
  };

  private _openItem(target: BaseAccordionItemElement) {
    if (!this.collapsible) {
      this._closeAllItems();
    }
    target.setAttribute('opened', '');
    this._setHash(target.id);
  }

  private _closeItem(target: BaseAccordionItemElement) {
    target.removeAttribute('opened');
    this._setHash();
  }

  private _closeAllItems() {
    this._items.forEach((item) => item.removeAttribute('opened'));
  }

  private _setHash(hash?: string) {
    history.replaceState(null, '', hash ? `#${hash}` : ' ');
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'accordion-content': AccordionContentElement;
  }
}
