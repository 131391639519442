import {
  LitElement,
  html,
  css,
  unsafeCSS,
  nothing,
  type PropertyValues,
  type TemplateResult,
} from 'lit';
import {property, query, queryAssignedNodes} from 'lit/decorators.js'; // eslint-disable-line import/extensions
import {classMap} from 'lit/directives/class-map.js'; // eslint-disable-line import/extensions

import {
  type AccordionToggleState,
  type AccordionToggleEvent,
  AccordionToggleEventName,
} from '../utils/accordionToggleStateEvent';
import {toCssUrl} from '../utils/css';

import accordionMinusSvg from '@/images/svg/accordion-minus.svg?url';
import accordionPlusSvg from '@/images/svg/accordion-plus.svg?url';

import {snakeCase} from '@/js/util/strings';

type OptionalTemplateResult = TemplateResult | typeof nothing;

export abstract class BaseAccordionItemElement extends LitElement {
  @property({type: Boolean})
  public accessor opened = false;

  @property({type: String})
  public accessor link = '';

  @query('#item-content')
  protected accessor itemContent: HTMLDivElement | null = null;

  @query('#item-header')
  protected accessor itemHeader: HTMLDivElement | null = null;

  @queryAssignedNodes({slot: 'title'})
  private accessor titleSlotNodes: Array<HTMLElement> = [];

  protected _defaultTitle = 'accordion-item';

  protected get _title() {
    return snakeCase(this.titleSlotNodes?.[0]?.textContent ?? this._defaultTitle);
  }

  public get id() {
    return this.link !== '' ? this.link : this._title;
  }

  static baseStyles = [
    css`
      :host {
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
        display: block;
        margin-bottom: 15px;
      }
      .accordion-header {
        border: 1px solid #000;
        border-radius: 0;
        margin-bottom: 0;
        position: relative;
      }
      .item-title {
        font-size: 1.25rem;
        margin: 0;
      }
      .title-link {
        align-items: center;
        color: #000;
        display: flex;
        font-weight: 600;
        line-height: 1.5rem;
        padding: 0.5rem 1.25rem;
        text-decoration: none;
      }
      .body {
        padding: 0.5rem 1.25rem;
      }
      .collapse {
        display: block;
      }
      .collapsed .collapse {
        max-height: 0;
        overflow: hidden;
      }
    `,
  ];

  static iconStyles = css`
    .title-link {
      /* Add additonal padding around plus icon */
      padding-right: 2.75rem;
    }
    .title-link::after {
      content: "";
      float: right;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      transform: translate(-100%, -50%);
      top: 50%;
      left: calc(100% - 0.75rem);
      width: 1.25rem;
      height: 1.25rem;
      background-image: ${unsafeCSS(toCssUrl(accordionMinusSvg))};
    }
    .collapsed .title-link::after {
      background-image: ${unsafeCSS(toCssUrl(accordionPlusSvg))};
    }
  `;

  static styles = [
    this.baseStyles,
    this.iconStyles,
  ];

  render() {
    return html`
      <div class="${classMap({'accordion-item': true, 'collapsed': !this.opened})}">
        <div id="item-header" class="accordion-header" role="tab">
          <a
            aria-expanded="false"
            class="title-link"
            data-toggle="collapse"
            role="button"
            href="#"
            @click="${this._toggle}"
          >
            ${ this._headerLeftTemplate() }
            ${ this._headerTitleTemplate() }
            ${ this._headerRightTemplate() }
          </a>
        </div>
        <div id="item-content" class="accordion-content collapse" role="tabpanel">
          <div class="body">
            ${ this._bodyHeaderTemplate() }
            <slot></slot>
            ${ this._bodyFooterTemplate() }
          </div>
        </div>
      </div>
    `;
  }

  protected _bodyHeaderTemplate(): OptionalTemplateResult {
    return nothing;
  }

  protected _bodyFooterTemplate(): OptionalTemplateResult {
    return nothing;
  }

  protected _headerLeftTemplate(): OptionalTemplateResult {
    return html`<slot name="left-header"></slot>`;
  }

  protected _headerTitleTemplate(): OptionalTemplateResult {
    return html`
      <h2 class="item-title">
        <slot name="title"></slot>
      </h2>
    `;
  }

  protected _headerRightTemplate(): OptionalTemplateResult {
    return html`<slot name="right-header"></slot>`;
  }

  shouldUpdate(changedProperties: PropertyValues<this>) {
    super.shouldUpdate(changedProperties);

    if (changedProperties.has('opened') && this.itemContent) {

      if (changedProperties.get('opened') === false) { // Opening the collapsible
        this.itemContent.style.overflow = 'hidden'; // Make sure overflow is hidden during animation
        const animation = this.itemContent.animate(
          {maxHeight: ['0', `${this.itemContent.scrollHeight}px`]},
          {easing: 'ease-in-out', duration: 250},
        );
        animation.onfinish = () => {
          // Scroll into view after the animation is finished
          this.itemHeader?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });
          this.itemContent!.style.overflow = ''; // Allow displaying overflow after animation
        };
      } else if (changedProperties.get('opened') === true) { // Closing the collapsible
        this.itemContent.animate(
          {maxHeight: [`${this.itemContent.scrollHeight}px`, '0']},
          {easing: 'ease-in-out', duration: 250},
        );
      }
    }

    return true;
  }

  close() {
    this._setState('close');
  }

  open() {
    this._setState('open');
  }

  openNext() {
    this._setState('openNext');
  }

  private _toggle(e: MouseEvent) {
    e.preventDefault();
    this._setState(this.opened ? 'close' : 'open');
  }

  private _setState(state: AccordionToggleState) {
    const event: AccordionToggleEvent = new CustomEvent(AccordionToggleEventName, {
      detail: {
        to: state,
      },
    });
    this.dispatchEvent(event);
  }
}
